.App {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

button {
  background: #61dafb;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0 16px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  cursor: pointer;
}

.sign-in {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sign-in button {
  margin-bottom: 10px;
}

.sign-in-google {
  background-color: #4285f4;
  width: 240px;
  height: 50px;
  border: 1px solid transparent;
  font-size: 16px;
  line-height: 48px;
}
