#root,
html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #242526;
  }

  input {
    background-color: #3a3b3c;
    color: white;
  }
  :root {
    color-scheme: dark;
    --message-received-background: #3e4042;
    --message-received-color: white;
  }
}

@media (prefers-color-scheme: light) {
  body {
    background-color: initial;
  }

  input {
    background-color: #f0f2f5;
  }
  :root {
    --message-received-background: #e4e6eb;
    --message-received-color: black;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
